import { createRouter,createWebHashHistory} from "vue-router"
import storage from "../static/utils/storage";

const routes = [
    {
        path: "/",
        redirect: "/login"
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('../page/404'),
    },
    {
        path: "/login",
        component: () => import('../page/logIn/LogIn'),
    },
    {
        path: '/unitManagement',
        component: () => import('../page/Main.vue'),
        redirect:'/unitManagement/List',
        name: '单位管理',
        children: [
            {
                path: 'list',
                name:'单位管理列表',
                component: () => import('../page/unitManagement/UnitManagement'),
                meta: {
                    requireAuth: true,
                    keepAlive:true,
                },
            },
            {
                path: 'detail',
                meta: {
                    requireAuth: true,
                    keepAlive: false,
                    //如果是三级路由在这边额外添加父类信息
                    parentInfo:{ path: "/unitManagement/list",name:"单位管理"}
                },
                name: '应用配置详情',
                component: () => import('../page/unitManagement/detail/UnitManagementDetail'),
            },
            {
                path: 'setting',
                meta: {
                    requireAuth: true,
                    keepAlive: false,
                    //如果是三级路由在这边额外添加父类信息
                    parentInfo:{ path: "/unitManagement/list",name:"单位管理"}
                },
                name: '应用配置',
                component: () => import('../page/unitManagement/setting/UnitManagementSetting'),
            },
        ]
    },
    {
        path: '/applicationManagement',
        component: () => import('../page/Main.vue'),
        redirect:'/applicationManagement/list',
        name: '应用列表',
        children: [
            {
                path: 'list',
                name:'应用列表',
                component: () => import('../page/applicationManagement/ApplicationManagement'),
                meta: {
                    requireAuth: true,
                    keepAlive:false,
                },
            }
        ]
    },
    {
        path: '/serviceCenter',
        component: () => import('../page/Main.vue'),
        redirect:'/serviceCenter/list',
        name: '服务中心',
        children: [
            {
                path: 'list',
                name:'服务列表',
                component: () => import('../page/serviceCenter/ServiceCenter'),
                meta: {
                    requireAuth: true,
                    keepAlive:false,
                },
            },
            {
                path: 'type',
                name:'服务类别',
                component: () => import('../page/serviceCenter/ServiceType'),
                meta: {
                    requireAuth: true,
                    keepAlive:false,
                },
            },
            {
                path: 'pushList',
                name:'推送服务列表',
                component: () => import('../page/serviceCenter/PushServiceCenter'),
                meta: {
                    requireAuth: true,
                    keepAlive:false,
                },
            },
            {
                path: 'pushType',
                name:'推送服务类别',
                component: () => import('../page/serviceCenter/PushServiceType'),
                meta: {
                    requireAuth: true,
                    keepAlive:false,
                },
            }
        ]
    },
    {
        path: '/logManagement',
        component: () => import('../page/Main.vue'),
        redirect:'/logManagement/list',
        name: '日志管理',
        children: [
            {
                path: 'list',
                name:'调试日志',
                component: () => import('../page/logManagement/LogManagement'),
                meta: {
                    requireAuth: true,
                    keepAlive:false,
                },
            }
        ]
    },
    {
        path: '/customerManagement',
        component: () => import('../page/Main.vue'),
        redirect:'/customerManagement/list',
        name: '客户管理',
        children: [
            {
                path: 'list',
                name:'客户列表',
                component: () => import('../page/customerManagement/CustomerList'),
                meta: {
                    requireAuth: true,
                    keepAlive:false,
                },
            }
        ]
    },
]

const router = createRouter({
    history: createWebHashHistory('/openWeb/'),
    routes: routes
})

router.beforeEach((to, from, next) => {
    if(to.matched.some(res => res.meta.requireAuth)) { // 判断是否需要登录权限
        if (storage.getItem("userInfo")) {
            next()
        } else {
            storage.clear()
            next({
                path: '/login'
            })
        }
    }else{
        next()
    }
})

export default router
