export default{
    state: {
        isCollapse:false,
        asideMenu:[],
        bkMenu:{}
    },

    mutations: {
        collapseMenu(state){
            state.isCollapse = !state.isCollapse;
        },
        /**
         * 权限处理侧边栏
         * @param state
         * @param bkmenu
         */
        handlerMenuAuth(state,bkmenu){
            console.log('....................')
            state.asideMenu=[]
            state.bkMenu=bkmenu
            let unitManagement= {
                path:"/unitManagement/list",
                label:"单位管理",
                icon:require('../../assets/images/icon_company.png'),
            }
            state.asideMenu.push(unitManagement);
            let applicationManagement= {
                path:"/applicationManagement/list",
                label:"应用列表",
                icon:require('../../assets/images/icon_project.png'),
            }
            state.asideMenu.push(applicationManagement);

            let serviceCenter= {
                path:"/serviceCenter",
                label:"服务中心",
                icon:require('../../assets/images/icon_service.png'),
                children:[]
            }
            serviceCenter.children.push({
                path:"/serviceCenter/list",
                label:"服务列表",
            })
            serviceCenter.children.push({
                path:"/serviceCenter/type",
                label:"服务类别",
            })
            serviceCenter.children.push({
                path:"/serviceCenter/pushList",
                label:"推送服务列表",
            })
            serviceCenter.children.push({
                path:"/serviceCenter/pushType",
                label:"推送服务类别",
            })
            state.asideMenu.push(serviceCenter);

            let logManagement= {
                path:"/logManagement",
                label:"日志管理",
                icon:require('../../assets/images/icon_daily_record.png'),
                children:[]
            }
            logManagement.children.push({
                path:"/logManagement/list",
                label:"调试日志",
            })
            state.asideMenu.push(logManagement);

            let customerManagement= {
                path:"/customerManagement",
                label:"客户管理",
                icon:require('../../assets/images/icon_daily_record.png'),
                children:[]
            }
            customerManagement.children.push({
                path:"/customerManagement/list",
                label:"客户列表",
            })
            state.asideMenu.push(customerManagement);
        }
    },
    actions:{

    }
}
