import getters from "./getters";
import createPersistedState from "vuex-persistedstate";
import tab from "./modules/tab";

import { createStore } from "vuex";

export default createStore({
    modules: {tab},
    getters: getters,
    plugins: [createPersistedState({
        //storage:window.sessionStorage,
        //局部持久化缓存
        paths: ['tab.isCollapse','tab.asideMenu','tab.bkMenu']
    })]
})
