import { createApp } from 'vue'
import App from './App.vue'

import router from './router/index'
import store from "./store/index"
import 'animate.css/animate.min.css'
//https://github.com/chenfengjw163/vue-json-viewer  显示JSON数据
import JsonViewer from 'vue-json-viewer'



// element-plus 全局引用
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

const app = createApp(App)

app.use(ElementPlus, {
    locale: zhCn,
})
app.use(JsonViewer)

app.use(router)
app.use(store)
app.mount('#app')
