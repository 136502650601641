<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
  #app{
    height: 100%;
    width: 100%;
    background: linear-gradient(170.25deg, rgba(56, 74, 107, 1) 0%, rgba(27, 38, 61, 1) 100%);
  }
  html,body{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
</style>
